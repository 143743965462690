import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Container, Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import PerfilCompleted from '../components/Registration/Completed/Successfull';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

const SuccessfullPage = ({ location }: { location: any }, { data }: any) => {
  const locationState = location.state;
  useEffect(() => {
    if (locationState == null) {
      navigate('/');
    }
  }, []);

  return (
    <>
      {locationState && (
        <>
          <Layout>
            <SEO title="Perfil completo" />
            <PerfilCompleted />
          </Layout>
        </>
      )}
    </>
  );
};

export default SuccessfullPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
