import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../images/Registration/pluma.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '72px',
      backgroundColor: `#FFF`,
      '& .MuiOutlinedInput-root': {
        height: 48,
      },
      '& .MuiInputLabel-outlined': {
        color: '#AAAAAA',
        fontSize: 16,
        [theme.breakpoints.down(501)]: {
          fontSize: 12,
        },
      },
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.down(501)]: {
        '& label': {
          fontSize: `12px`,
        },
        '& input': {
          fontSize: `13px`,
        },
      },
    },
    container: {
      display: 'flex',
      color: '#004F71',
      paddingLeft: `0%`,
      paddingRight: `0%`,
      height: 850,
      '& h4': {
        textAlign: `center`,
      },
      [theme.breakpoints.down(501)]: {
        height: 800,
      },
      '& h1': {
        textAlign: `center`,
        lineHeight: '35px',
        fontWeight: 'normal',
        fontSize: 32,
        margin: 0,
        [theme.breakpoints.down(501)]: {
          fontSize: 26,
        },
      },
      '& p': {
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        [theme.breakpoints.down(501)]: {
          fontSize: 14,
        },
        '&:first-child': {
          margin: '27px 0 0 0',
        },
        '&:nth-child(2)': {
          margin: '40px 0 0 0',
        },
      },
      '& h2': {
        [theme.breakpoints.down(501)]: {
          fontSize: 16,
        },
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
    },
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
      header: {
        display: 'none',
      },
    },
    feather: {
      float: `right`,
      width: `100%`,
      height: `100%`,
      backgroundImage: `url(${background})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `right`,
      backgroundSize: `cover`,
      // backgroundColor:`#FF3`,
    },
    rightCol: {
      paddingTop: 80,
      [theme.breakpoints.down(769)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
      [theme.breakpoints.down(500)]: {
        paddingLeft: `5%`,
        paddingRight: `5%`,
        paddingTop: 42,
      },
    },
    button: {
      borderRadius: `30px`,
      backgroundColor: `#F8B133`,
      margin: '33px auto 0 auto',
      height: 48,
      width: 329,
      fontWeight: 600,
      fontSize: 20,
      textTransform: `capitalize`,
      '&:hover': {
        background: '#074F71',
        color: `#FFF`,
      },
    },
  })
);

export default useStyles;
